import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Tabs`}</em>{` are used to quickly navigate between views within the same context.`}</p>
    <h2 {...{
      "id": "format"
    }}>{`Format`}</h2>
    <h4 {...{
      "id": "text"
    }}>{`Text`}</h4>
    <p>{`Each tab label describes its content and sets user expectations. Labels are concise and use one to two words maximum. Keep in mind that at mobile widths, the character length of a title will impact the experience. Icons are not permitted in tab labels.`}</p>
    <h4 {...{
      "id": "number-of-tabs"
    }}>{`Number of tabs`}</h4>
    <p>{`A maximum of six tabs may be displayed. This is to maintain an uncluttered UI and reduce cognitive load for users.`}</p>
    <h4 {...{
      "id": "order"
    }}>{`Order`}</h4>
    <p>{`Tab order should be consistent across an experience. Tabs with related content should be grouped adjacent to each other.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAAB9UlEQVQ4y5WTz08aQRTH3y4Lq0hZkQILtv4WTTeBC3+EaSqnsuwEAn9DXTVRYlYvpof+A6XqrUkTjTGBi/FkzMaeuNT0xLEX/ortm80buoo24SWfzJudN9958+YttNtt4NbpdGBvfx8c5wA8z4OUUYaZ9fcAjgc7n3/A7s4WnJye+Wvc+v0+vGhcbBwTos+ayLDb7cqtVit5dOhouEFKGx9SybWNDBx5sn38fWLb/pT52v4WxTWZxzuOA6ZpDuHmj67r+pNer6ecX1wkri7Ptc7NfUwzf8ZDm3ca1L3ol8s/U+7ttfbr4XdsMBiozyUmRB+ZoiiaqqrTkXDolZsEyb+eMhrXaDSkSqUio4jgn2iz2fQntm2DZVlpJGtZTK+YTP9osjSiVy2WY4ylLcZ0HoMbU4iOZBFNiPmCQh1P5H4OeYPMIfPILPlvaXOOWKS1WYoPDzPkQiQoIzxwBVlFlkh0hXzxjZMPjK8RaShYr9d9QRylWq2WQ/RqtSpO55klkCgSo3EKmRT+fx8FLYKEx+nJkbYpFAr+xDAMqVgszpRKpUS5XFax+CoGqJSN+oQJGkMj2eEVg1dewNfMU9FXqUbvqI55+raGLAdqG3+UaeBReG9lEd4OGWqLFPm8nvFAxpGAH3569RcLOk4dRybB/3Jcgjp/Ae4N8TDIemcmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "An example of tabs being used.",
            "title": "An example of tabs being used.",
            "src": "/static/f6ce00120f965f17c2ea0f5c3ed5b3c8/fb070/tab-usage-1.png",
            "srcSet": ["/static/f6ce00120f965f17c2ea0f5c3ed5b3c8/d6747/tab-usage-1.png 288w", "/static/f6ce00120f965f17c2ea0f5c3ed5b3c8/09548/tab-usage-1.png 576w", "/static/f6ce00120f965f17c2ea0f5c3ed5b3c8/fb070/tab-usage-1.png 1152w", "/static/f6ce00120f965f17c2ea0f5c3ed5b3c8/c3e47/tab-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      